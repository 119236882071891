export interface UseCategoryState {
  data: any;
  bigCommerceCategory: any;
  categoryDescription: any;
  loadingItems: boolean;
}

const useCategory = () => {
  const state = useState<UseCategoryState>(`useCategory`, () => ({
    data: null,
    bigCommerceCategory: null,
    categoryDescription: null,
    loadingItems: false,
  }));

  const { selectedBranchId } = useBranches();

  const bloomreachCookie = useCookie("_br_uid_2");
  const viewId = computed(() => (selectedBranchId.value ? `b_${selectedBranchId.value}` : "default"));
  const brUid = computed(() => (bloomreachCookie ? bloomreachCookie.value : "noBrCookie"));
  const { BR_ENV_TYPE } = useRuntimeConfig().public;

  const fetchCategory = async (categoryID: string) => {
    const { data: categoryData, error } = await useTimedAsyncData("getCategoryBC", () =>
      useSdk().bcAPI.getCategory({ categoryId: Number(categoryID) }),
    );

    state.value.bigCommerceCategory = categoryData.value ?? state.value.data;
    state.value.categoryDescription = JSON.parse(categoryData.value?.description);

    useHandleError(error.value);

    return categoryData.value;
  };

  type QueryParams = {
    categoryId: string;
    itemsPerPage: number;
    offset: number;
    filterValues: any;
    sortTerm: any;
    onlyTotal: boolean;
  };

  const fetchItemsByCategory = async ({
    categoryId,
    itemsPerPage,
    offset,
    filterValues,
    sortTerm,
    onlyTotal = false,
  }: QueryParams) => {
    state.value.loadingItems = true;

    const { data, error } = await useTimedAsyncData("findItemsByCategoryBR", () =>
      useSdk().brSearch.findItemsByCategory({
        queryName: "findItemsByCategory",
        categoryId,
        offset,
        limit: itemsPerPage,
        sortFields: sortTerm,
        // expect: "id",
        queryHint: {
          viewId: viewId.value,
          brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
          brUid2: brUid.value ?? undefined,
          facetFieldFilters: filterValues,
          customAttrFields: [
            "bcid",
            "brand",
            "url",
            "is_tally",
            "price",
            "price_inc_vat",
            "offer_price",
            "offer_price_inc_vat",
            "availability_flag",
            "availability_reason",
            "cf_width",
            "unit",
            "offer_unit",
            "cf_thickness",
            "cf_treatment",
            "cf_grade",
            "cf_icon-green",
            "cf_icon-energy",
            "cf_icon-materials",
            "cf_icon-planet",
          ],
        },
      }),
    );

    state.value.data = data.value ?? state.value.data;
    state.value.loadingItems = false;
  };

  const search = async ({
    query,
    filterValues,
    sortTerm,
    itemsPerPage,
    offset,
  }: {
    query: string;
    filterValues: any;
    sortTerm: any;
    itemsPerPage: number;
    offset: number;
  }) => {
    state.value.loadingItems = true;

    const { data, error } = await useAsyncData("findItemsByKeyword", () =>
      useSdk().brSearch.findItemsByKeyword({
        text: query,
        limit: itemsPerPage,
        offset: offset,
        sortFields: sortTerm,
        queryHint: {
          viewId: viewId.value,
          brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
          brUid2: brUid.value ?? undefined,
          facetFieldFilters: filterValues,
          customAttrFields: [
            "bcid",
            "brand",
            "url",
            "is_tally",
            "price",
            "price_inc_vat",
            "offer_price",
            "offer_price_inc_vat",
            "availability_flag",
            "availability_reason",
            "cf_width",
            "unit",
            "offer_unit",
            "cf_thickness",
            "cf_treatment",
            "cf_grade",
            "cf_icon-green",
            "cf_icon-energy",
            "cf_icon-materials",
            "cf_icon-planet",
          ],
        },
        expect:
          "queryHint { cursor autoCorrectQuery autoCorrectQuerySet redirectHint { url } } total limit offset facetResult { fields { id name values { id parentId name count } } } items { slug itemId { id } displayName description purchasePrice { moneyAmounts { amount currency displayValue } } listPrice { moneyAmounts { amount currency displayValue } } imageSet { original { dimension { w h } link { href ref } } thumbnail { dimension { w h } link { href ref } } } salePriceRange priceRange customAttrs { name values } variants { itemId { id } displayName description master imageSet { original { dimension { w h } link { href ref } } thumbnail { dimension { w h } link { href ref } } } purchasePrice { moneyAmounts { amount currency displayValue } } listPrice { moneyAmounts { amount currency displayValue } } mainItem { itemId { id } displayName } customAttrs { name values } availability { inStock availableQuantity restockableInDays } } }",
      }),
    );

    state.value.data = data.value ?? state.value.data;

    state.value.loadingItems = false;
  };

  return {
    search,
    fetchCategory,
    fetchItemsByCategory,
    ...toRefs(state.value),
  };
};

export default useCategory;
