export default (path: string) => {
  if (path.endsWith("/")) {
    return path;
  }
  return path + "/";
};

export function target(path: string) {
  if (!path) {
    return "";
  }
  if (path.startsWith("/")) {
    return "_self";
  }
  return "_blank";
}
