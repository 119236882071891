import pickBy from "lodash.pickby";

export const combineAllFacets = (fields: any, range: any) => {
  const rangeFacets = range.filter((f: any) => f.name !== "price");

  const updatedRangeFacets = rangeFacets.reduce((acc: any[], facet: any) => {
    const editedValues = facet.values
      .map(({ count, end, start }: { count: any; end: any; start: any }) => {
        if (start === "*") {
          return { count, name: `Less than ${end}`, id: `[* TO ${end}]` };
        } else if (end === "*") {
          return { count, name: `More than ${start}`, id: `[${start} TO *]` };
        } else {
          return { count, name: `${start} - ${end}`, id: `[${start} TO ${end}]` };
        }
      })
      .filter((facet: any) => facet.count !== 0);

    if (editedValues.length >= 2) {
      const firstValue = editedValues[0];
      const lastValue = editedValues[editedValues.length - 1];

      if (!firstValue.name.includes("Less")) {
        const end = firstValue.name.split(" - ")[1];
        firstValue.name = `Less than ${end}`;
        firstValue.id = `[* TO ${end}]`;
      }

      if (!lastValue.name.includes("More")) {
        const start = lastValue.name.split(" - ")[0];
        lastValue.name = `More than ${start}`;
        lastValue.id = `[${start} TO *]`;
      }
    }

    if (editedValues.length > 0) {
      acc.push({ ...facet, values: editedValues, isRangeFilter: true });
    }

    return acc;
  }, []);

  return [...fields, ...updatedRangeFacets];
};

export const checkIfFilterIsSelected = (filters: any) => {
  const params: { [key: string]: any } = {};

  Object.keys(filters.value).forEach((facetId) => {
    if (facetId === "price") {
      params.from = filters.value.price?.from || undefined;
      params.to = filters.value.price?.to || undefined;
    } else if (facetId === "category") {
      params[facetId] = filters.value[facetId].map((it: any) => it.id).join(",") || undefined;
      return;
    } else if (Object.keys(filters.value[facetId] || []).length > 0) {
      params[facetId] = filters.value[facetId].join(",");
    }
  });

  return pickBy(params, (v) => v !== undefined);
};
