export interface DataNode {
  url: string;
  children?: DataNode[];
}

export const findByUrl = (data: any[], url: string): any | undefined => {
  let result: DataNode | null = null;

  const searchNode = (node: DataNode): boolean => {
    if (node.url === url) {
      result = node;
      return true;
    }
    return Array.isArray(node.children) && node.children.some(searchNode);
  };

  data.some(searchNode);
  return result;
};
