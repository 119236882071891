export const checkIfItsBrowserStepBack = (baseUrl: any) => {
  if (baseUrl.includes("?")) {
    const index = baseUrl.indexOf("?");
    return baseUrl.slice(0, Math.max(0, index));
  }
  return baseUrl;
};

export const checkForPaginationInURL = (url: any) => {
  // Check the URL if included query parameter for a page
  // For example: mkm.com/category/plumbing-heating/plumbing-pipework/plastic-pipe-fittings?p=4
  // And convert the current page to Number
  // In order to use this value for an offset when make fetchByCategory request to the BR

  if (url.includes("p=")) {
    // If "p=" is part of the url, substring it and parse the page number to Int
    const indexOfPageQuery = url.indexOf("p=");
    const currentPage = url.slice(indexOfPageQuery + 2, url.length);
    return Number.parseInt(currentPage);
  }
  return -1;
};
